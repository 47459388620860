import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useStore = create(
    //Se declaran estos estados de manera global y se pasan por el método persist (guardandolos en el objeto "userData", en el localStorage) hasta que se cierre sesión
    persist(
        (set, get) => ({
            /*Estado que guarda el array de ids de laboratorio y grupo laboratorio */
            setIdsLaborat: (data) => set((state) => ({ idsLaborat: data })),
            idsLaborat:null,
            /*Estado que setea si el usuario se encuentra loggeado */
            setIsLogged: (data) => set((state) => ({ isLogged: data })),
            isLogged:false,
            //Trae el nombre de laboratorio (no utilizado)
            setLaboNombre: (data) => set((state) => ({ laboNombre: data })),
            laboNombre:null,
            /*Estado que almacena el nombre de usuario */
            setUsername: (data) => set((state) => ({ username: data })),
            username:null,
            setNombre: (data) => set((state) => ({ nombre: data })),
            nombre:null,
             /*Estado que guarda el array de permisos de cada usuario */
            setUserPermisos: (data) => set((state) => ({ userPermisos: data })),
            userPermisos:[],
            /*Setea la fecha actual al cambiar el estado isLogged de la App */
            setHoyG: (data) => set((state) => ({ hoyG: data })),
            hoyG:null,
            /*Funcion que vacía los datos enviados al localStorage al momento que se desloguea el usuario */
            handleLogout: (data) => set((state) => ({ idsLaborat:null,isLogged:false,userName:null,nombre:null, laboNombre:null })),
            //Guarda medico_id. NULL si el user no lo es
            setMedico_id: (data) => set((state) => ({ medico_id: data })),
        }),
        {
            name: 'userData',
        }
    )
)