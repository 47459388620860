import React from "react";
import { Table } from "react-bootstrap";

const TablaDerivaciones = ({ entradaD }) => {
    
    return(
        <>
            <Table>
                <thead>
                    <tr id="tablaHeaderBuscador">
                        <th>Tubo</th>
                        <th>Enviado</th>
                        <th>Pendiente de control</th>
                        <th>Recibido</th>
                        <th>Listo</th>
                        <th>Controlado (devuelto)</th>
                    </tr>
                </thead>

                <tbody>
                   {entradaD && <>
                    {entradaD.map((item, index) => (
                        <tr key={index} className={index}>
                            <td className={index}>{item}</td>
                            <td className={index}>{item}</td>
                            <td className={index}>{item}</td>
                            <td className={index}>{item}</td>
                            <td className={index}>{item}</td>
                        </tr>
                    ))}
                   </>} 
                </tbody>
            </Table>
        </>
        )
};

export default TablaDerivaciones;