import React from "react";
import { Button, Tab, Table, Tabs } from "react-bootstrap";

const AjustesDerivacion = () => {

  return (
    <form>
      <Tabs defaultActiveKey="config-deriv">

        <Tab eventKey='config-deriv' title='Configuración de Derivantes' tabClassName='tab-deriv'>
          <Table className='tabla-ajustes'>
            <thead>
              <tr>
                <th>Código</th>
                <th>Laboratorio</th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>
          </Table>
          <div className='btns-ajustes'>
            <Button variant='primary'>Nuevo Derivante</Button>
            <Button variant='primary'>Modificar Derivante</Button>
            <Button variant='secondary'>Eliminar Derivante</Button>
          </div>
        </Tab>

        <Tab eventKey='config-equiv' title='Configuración de Equivalencias' tabClassName='tab-deriv'>
          <Table className='tabla-ajustes'>
            <thead>
              <tr>
                <th>Código Intercambio</th>
                <th>Análisis</th>
                <th>Código Análisis</th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>
          </Table>
          <div className='btns-ajustes'>
            <Button variant='primary'>Nueva Equivalencia</Button>
            <Button variant='primary'>Modificar Equivalencia</Button>
            <Button variant='secondary'>Eliminar Equivalencia</Button>
          </div>
        </Tab>

        <Tab eventKey='config-rutina' title='Configuración de Rutinas' tabClassName='tab-deriv'>
          <Table className='tabla-ajustes'>
            <thead>
              <tr>
                <th>Rutina</th>
                <th>Análisis</th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>
          </Table>
          <div className='btns-ajustes'>
            <Button variant='primary'>Nueva Rutina</Button>
            <Button variant='primary'>Modificar Rutina</Button>
            <Button variant='secondary'>Eliminar Rutina</Button>
          </div>
        </Tab>

      </Tabs>
      <Button className="btn-paciente" type="submit" variant="info">Guardar</Button>
    </form>

  )
}

export default AjustesDerivacion;