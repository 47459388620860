import React from "react";
import Buscador from "../components/Buscador"
import TablaResultados from "../components/TablaResultados";
import Marquesina from "../components/Marquesina";
import { BtnLogout } from "../components/BtnLogout";
import { useStore } from "../store/store";
import { Navigate, useNavigate } from "react-router-dom";
import { NombreUsuario } from "../components/NombreUsuario";
import { useStoreBuscador } from "../store/storeBuscador";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import(".././Styles/visor.css")
import(".././Styles/componentsStyles/buscador.css")
import(".././Styles/componentsStyles/tablaResultados.css")
import(".././Styles/componentsStyles/marquesina.css")


const Visor = () => {
  /* Estados, setResult obtiene el resultado de la busqueda parametrizada en Buscador, el cual lo recibe el componente padre (este mismo, visor)
  y se lo envia a TablaResultados con result como prop */
  //const [result, setResult] = useState();

  //Estados globales traidos del store
  const userPermisos = useStore((state) => state.userPermisos)
  const result = useStoreBuscador((state) => state.result)
  const setResult = useStoreBuscador((state) => state.setResult)
  const setPaginaActual = useStoreBuscador((state) => state.setPaginaActual)
  const laboNombre = useStore((state) => state.laboNombre)

  //Buscar la posibilidad de leer todos los permisos al loguearse (sin necesidad de declarar la funcion leerPermisos() en cada componente)
  function leerPermisos (idP){
    const permisoId = userPermisos.find((e) => e === idP)
    return permisoId
  }

  const navigate = useNavigate()
  function volverOpciones(){
    navigate("/opciones")
  }

  useEffect(() => {
    setPaginaActual('visor')
    setResult(null)
  }, [])
  
  if (userPermisos.find((e)=> e === 110)){
    return (
        <div className="visor">
          <BtnLogout />

          <div className="first-line">
            <Button variant="outline-secondary" onClick={() => volverOpciones()} id='btnVolver'>
              <img alt='volver' src='./Imagenes/anterior.png' />
            </Button>
            <img className='laboratorio' src="./Imagenes/carga-resultados.png" alt="icon"/>
            {leerPermisos(113) ? 
              <p className='labName'>{laboNombre} - Visor de Resultados</p> : 
              <p className='labName'>{laboNombre} - Validador de Resultados</p>
            }
            <NombreUsuario />
          </div>
          
          <Buscador envioDeEntradas={setResult} envioPermisos={leerPermisos} />
  
          <div id="tablaScroll">
            <TablaResultados entrada={result} envioPermisos={leerPermisos} />
  
            {result <= 0 &&
              <h3 id='sin-result'>Sin resultados</h3>}
          </div>
  
          <div className="marquesina">
            <Marquesina/>
          </div>
      </div>
  
    )
  } else {return <Navigate to="/opciones" replace />}
}

export default Visor;