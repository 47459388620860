import React, { useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

const NuevaDerivacion = () => {
  const [tipoDoc, setTipoDoc] = useState([])

  //Metodo para traer tipo de doc
  const request = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  };

  //Trae tipos de documentos
  function tipoDocDB() {
    fetch("http://localhost:3030/buscador/tipoDoc", request)
      .then((resp) => resp.json())
      .then((data) => {
        setTipoDoc(data)
        //console.log(data)
      })
      .catch(() => {
        console.log("La peticion fallo en tipoDoc")
      })
  }

  useEffect(() => {
    tipoDocDB()
  })


  return (
    <form>
      <Tabs defaultActiveKey="datos">

        <Tab tabClassName="tab-deriv" eventKey="datos" title="Datos Personales del Paciente">

          <div className="linea-paciente">
            <div className="input-paciente" >
              <label htmlFor="tipoDoc">Tipo de Documento:</label>
              <select name="tipoDoc" id="tipoDoc">
                <option></option>
                {tipoDoc ? <>
                  {tipoDoc.map((item, index) => (
                    <option key={index}> {item.nombre} </option>
                  ))}
                </> : <option>--</option>
                }
              </select>
            </div>

            <div className="input-paciente">
              <label htmlFor="documento">Numero de documento:</label>
              <input id="documento" type="number" placeholder="Documento" ></input>
            </div>

            <div className="input-paciente" >
              <label htmlFor="nombre">Nombre/s:</label>
              <input id="nombre" name="nombre" type="text" placeholder="Nombre del paciente"></input>
            </div>

            <div className="input-paciente" >
              <label htmlFor="apellido">Apellido:</label>
              <input id="apellido" name="apellido" type="text" placeholder="Apellido del paciente"></input>
            </div>
          </div>

          <div className='linea-paciente'>
            <div className="input-paciente">
              <label htmlFor="sexo">Sexo:</label> <br />
              <select name="sexo" id="sexo">
                <option></option>
              </select>
            </div>

            <div className="input-paciente">
              <label htmlFor="genero">Género: </label>
              <select name="genero" id="genero">
                <option></option>
              </select>
            </div>

            <div className="input-paciente">
              <label htmlFor="fechaNac">Fecha de Nacimiento: </label>
              <input type="date" name="fechaNac" id="fechaNac"></input>
            </div>
          </div>

          <div className='linea-paciente'>
            <div className='input-paciente'>
              <p>Contactos: </p>
            </div>

            <div className='input-paciente'>
              <label htmlFor="nombreContacto">Nombre: </label>
              <input name="nombreContacto" id="nombreContacto" type="text"></input>
            </div>

            <div className='input-paciente'>
              <label htmlFor="telContacto">Teléfono:</label>
              <input name="telContacto" id="telContacto" type="number"></input>
            </div>
          </div>

          {/* <div className='linea-paciente'>

                                <div className='input-paciente'>
                                    <label htmlFor="nombreContacto">Nombre: </label>
                                    <input name="nombreContacto" type="text"></input>
                                </div>

                                <div className='input-paciente'>
                                    <label htmlFor="telContacto">Teléfono:</label>
                                    <input name="telContacto" type="number"></input>
                                </div>
                            </div> */}

          <div className="linea-paciente">
            <div className="input-paciente-obs">
              <label htmlFor="observaciones">OBSERVACIONES:</label>
              <input id="observaciones" name="observaciones" className="input-paciente-obs" type="text"></input>
            </div>
          </div>

        </Tab>

        <Tab tabClassName="tab-deriv" eventKey="orden" title="Detalle de la Orden">
          <div className="botonera-rutinas">
            <h2>Rutinas</h2>
            <div>
              <ButtonGroup className="btn-rutina">
                <Button variant="light">Pre-Ocupacional</Button>
                <Button variant="secondary">Pre-Quirúrgico</Button>
                <Button variant="light">Hemograma Completo</Button>
              </ButtonGroup>
            </div>
          </div>
          <div className="botonera-analisis">
            <h2>Análisis</h2>
            <div>
              <div>
                {/*------------------------PROVISORIO(se deben traer los analisis de BBDD)!!!!!!!------------------------*/}
                <label htmlFor="analisis"></label>
                <input className="input-analisis" list="analisis" id="analisis" />
                <datalist id="analisis">
                  <option value='VDRL' />
                  <option value='Orina Completa' />
                  <option value='Hemograma' />
                  <option value='HIV' />
                  <option value='Eritrosedimentacion' />
                </datalist>
                {/*------------------------------------------------------------------------------------------------------*/}
              </div>
            </div>
          </div>

          <div className="analisis-agregados">
            <h2>Agregados</h2>
            <div style={{ backgroundColor: 'lightblue', height: '72vh' }}></div>
          </div>
        </Tab>

        <Tab tabClassName="tab-deriv" eventKey="tubo" title="Detalle de Tubos">

        </Tab>

      </Tabs>
      <Button className='btn-paciente' type="submit" variant="info">Guardar</Button>
    </form>
  );
}

export default NuevaDerivacion;