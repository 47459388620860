import React from 'react'
import Clock from 'react-live-clock';
import '../Styles/opciones.css';
import { useStore } from '../store/store';

export const NombreUsuario = () => {
  const nombre = useStore((state) => state.nombre)
  return (
    <div className='user-msg'> 
        <Clock className='hora' format="HH:mm:ss" ticking={true} />
        <h2>Usuario: {nombre}</h2>
    </div>
  )
}
