import React, { useState } from "react";
import Detalle from "./Detalle"
import Table from 'react-bootstrap/Table';
import {differenceInYears} from 'date-fns'


//import { OverlayTrigger, Tooltip } from "react-bootstrap";

const TablaResultados = ({ entrada , envioPermisos }) => {
  //Maneja el estado de cuando se abre o cierra el modal
  const [show, setShow] = useState(false);
  //Hace que le modal se oculte
  const handleClose = () => setShow(false);
  //Hace que el modal se abra
  const handleShow = () => setShow(true);
  
  //Obtiene el indice que identifica a la entrada clickeada, 
  const [indiceEntrada, setindiceEntrada] = useState();

  //convierte formato YYYY/MM/DD a DD-MM-YYYY
  function fechaTabla(fecha) {
    let year = fecha.slice(0,4)
    let month = fecha.slice(5,7)
    let day = fecha.slice(8,10)

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    let fechaFormateada = (day + "-" + month + "-" + year)
    return fechaFormateada
  }
  
  function hideModal(props) {
    props.setShowModal(false);
  }
  
  //Pasado a cada fila de la tabla para que al hacer click se muestre el detalle de cada entrada
  function elegirEntrada (e){handleShow(e) ; setindiceEntrada(e.target.className)}

  //const mostrarOrden = () => {<OverlayTrigger overlay={<Tooltip>Orden</Tooltip>}/>}
  
  //Calcula edad de paciente, restando el dia de hoy menos su fecha de nacimiento. Con date-fns
  function calcEdad(fechaNacimiento){
    const HOY = new Date()
    const nacimiento = new Date(fechaNacimiento)
    let edad = differenceInYears(HOY, nacimiento) 
    return edad
  }

  return (
    <>
      <Table striped bordered hover id="tablaBusqueda">
        <thead>
          <tr id="tablaHeaderBuscador">
            <th>Apellido y Nombres</th>
            <th>Documento</th>
            <th>Edad</th>
            <th>Medico solicitante</th>
            <th>Servicio</th>
            <th>Fecha</th>
            <th>Entrada N°</th>
            <th>Estado</th>
          </tr>
        </thead>
        
        <tbody className="resultados">

          {/* Si la prop entrada no es undefined,  entra en el map que trae las entradas que cumplan con los criterios del buscador */}
          {entrada && <>
            {entrada.map((item, index) => (
             
              //el title son los analisis principales 
              <tr title={[item.analisis].toString().replaceAll(",","\n")} key={index} className={index} onClick={elegirEntrada} >

                <td className={index} > {item.apellido +", "+ item.nombres}</td>

                <td className={index} > {item.nro_doc.toLocaleString('es')}</td>

                {/* <td className={index} > {item.edad < 120 ? item.edad : '-'}</td> */}
                <td className={index} > {calcEdad(item.fecha_nacimiento) < 120 ? calcEdad(item.fecha_nacimiento) : '-'  }</td>

                <td className={index} > {item.medico}</td>

                <td className={index} > {item.servicio}</td>

                <td className={index} > {fechaTabla(item.fecha)}</td>
                
                <td className={index} > {item.rango + item.entrada}</td> 

                <td className={index} > {item.Estado} </td>

              </tr>
            ))}</>
          }
        </tbody>

      </Table>
      {/* Modal con los detalles de UNA entrada. Contiene props para abrir y cerrar*/}
      {(indiceEntrada !== undefined && indiceEntrada <= entrada.length)   && 
      <Detalle 
        mostrar={show}
        handleClose={handleClose} 
        entradaU={entrada[indiceEntrada]}
        hideModal={hideModal}
        envioPermisos={envioPermisos}
      />}

    </>
  )
}

export default TablaResultados