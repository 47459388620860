import React, { useState } from "react";
import Menu from "../components/derivacionesComponents/Menu";
import FiltroDerivaciones from "../components/derivacionesComponents/FiltroDerivaciones";
import TablaDerivaciones from "../components/derivacionesComponents/TablaDerivaciones";
import Marquesina from "../components/Marquesina";
import { BtnLogout } from "../components/BtnLogout";
import { useStore } from "../store/store";
import { Navigate } from "react-router-dom";
import(".././Styles/derivaciones.css")
import(".././Styles/componentsStyles/modalDerivacion.css")


const Derivaciones = () => {

  /* Estados, setResult obtiene el resultado de la busqueda parametrizada en FiltroDerivaciones, el cual lo recibe el componente padre 
  (este mismo, derivaciones) y se lo envia a TablaDerivaciones con result como prop */
  const [result, setResult] = useState();
  //Estados globales traidos del store
  const handleLogout = useStore((state) => state.handleLogout)
  const userPermisos = useStore((state) => state.userPermisos)

  if (userPermisos.find((e) => e === 113)) {
    return <Navigate to="/opciones" replace />
  } else {
    return (
      <div className='derivaciones'>

        <Menu />

        <div className='head-deriv'>
          <FiltroDerivaciones filtroDeEntradas={setResult} />
        </div>

        <div className='body-deriv'>
          <TablaDerivaciones entradaD={result} />
          {result <= 0 &&
            <h3 id='sin-result'>Sin resultados</h3>}
        </div>

        <BtnLogout handleLogout={handleLogout} />

        <div className="marquesina">
          <Marquesina />
        </div>

      </div>
    )
  }
}

export default Derivaciones;