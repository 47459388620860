import React, { useState } from 'react';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import {NombreUsuario} from '../components/NombreUsuario';
import { BtnLogout } from '../components/BtnLogout';
import Marquesina from '../components/Marquesina';
import { useStore } from '../store/store';
import { useStoreBuscador } from '../store/storeBuscador';
import '../Styles/opciones.css';

const Opciones = () => {

  //Setea la opción elegida para la navegación de la app en el switch
  const [opcionElegida, setOpcionElegida] = useState('');

  //Estados globales traidos del store
  const userPermisos = useStore((state) => state.userPermisos)
  const vaciarBusqueda = useStoreBuscador((state) => state.vaciarBusqueda)
  const setPaginaActual = useStoreBuscador((state) => state.setPaginaActual)

  //En caso de no tener los permisos para entrar a las rutas de cada botón, se alerta al usuario (posible cierre de sesión)
  function sinPermisos(){alert("NO POSEE LOS PERMISOS PARA INGRESAR A ESTA SECCIÓN. SOLICITELOS EN CASO DE SER NECESARIOS")}

  //Aviso sobre cada boton en caso de no tener permisos
  //const tooltipSinPermisos = <Popover className='tooltip-sin-permisos'>NO POSEE PERMISOS</Popover>

  const ingresoVisor = leerPermisos(110) //Mostrar Visor
  const ingresoMedico = leerPermisos(113) //Es Medico
  const ingresoBioquímico = leerPermisos(114) //Es Bioquimico

  const btnVisorDeEstados = 
    <Button 
      variant='outline-secondary'
      disabled = {ingresoMedico}
      id="estadosLab" 
      className='btn-user-body' 
      onClick={() => handleOptionSelect('estadosLab')}>
        <img alt='estadoLab' src='./Imagenes/trabajo-diario.png' style={ingresoMedico ? {filter:'grayscale(1)'} : {zoom:'150%'}} className='img-btn-opcs'/>
    </Button>

  const btnCargarOrden = 
    <Button 
      variant="outline-secondary" 
      className='btn-user-body' 
      id="orden" 
      disabled 
      onClick={() => alert("Cargar orden")}>
      <img alt='orden' src='./Imagenes/mesa-entradas.png' style={{filter:'grayscale(1)', zoom:'90%'}} className='img-btn-opcs'/>
    </Button>
    
  const btnMostrarResultados = 
    <Button 
      variant="outline-secondary" 
      id="visor"
      className='btn-user-body' 
      onClick={ingresoVisor ? () => handleOptionSelect('visor') : () => sinPermisos()}>
        <img alt='visor' src='./Imagenes/carga-resultados.png' style={{zoom:'150%'}} className='img-btn-opcs'/>
    </Button>

  const btnEstadisticas =
    <Button 
      variant="outline-secondary" 
      disabled = {ingresoMedico}
      className='btn-user-body' 
      id='estadistica' 
      onClick={() => handleOptionSelect('estadisticas')}>
      <img alt='estadistica' src='./Imagenes/estadisticos.png' className='img-btn-opcs' style={ingresoMedico ? {filter:'grayscale(1)'} : {zoom:'150%'}} />
    </Button>

  const btnDerivaciones = 
    <Button 
      variant="outline-secondary" 
      disabled
      //disabled = {ingresoMedico}
      id="derivaciones"
      className='btn-user-body'
      onClick={() => handleOptionSelect('derivaciones')}>
        <img alt='derivaciones' src='./Imagenes/trabajoDerivacion.png' className='img-btn-opcs' style={{filter:'grayscale(1)', zoom:'90%'}} /* style={ingresoMedico ? {filter:'grayscale(1)'} : {zoom:'150%'}} */ />
    </Button>

  //busca en el array userPermisos los id de los permisos de cada seccion, si los encuentra, deja avanzar a la siguiente ventana
  function leerPermisos (idP){
    const permisoId = userPermisos?.find((e) => e === idP)
    return permisoId
  }

  useEffect(()=>{
    vaciarBusqueda()
    setPaginaActual('opciones')
  }, [])

  //Funcion para manejar la seleccion de opcion
  const handleOptionSelect = (opcion) => {
    setOpcionElegida(opcion)}

  switch (opcionElegida) {
    case 'visor':
      return ingresoVisor ? <Navigate to="/visor" replace /> : <Navigate to="/opciones" replace />;
    case 'derivaciones':
      return ingresoMedico ? <Navigate to="/opciones" replace /> : ingresoBioquímico && <Navigate to="/derivaciones" replace />;
    case 'estadisticas':
      return ingresoMedico ? <Navigate to="/opciones" replace /> : ingresoBioquímico && <Navigate to="/estadisticas" replace />;
    case 'estadosLab':
      return ingresoMedico ? <Navigate to="/opciones" replace /> : ingresoBioquímico && <Navigate to="/estadosLab" replace />;

    default:
      return (
        <div className='opciones' style={{overflow:'hidden'}}>
          <BtnLogout />
          <div className='user-title'>
            <img alt="orbital-cloud" id="img-user" src="./Imagenes/Cloud3.png"/>
            <NombreUsuario />
          </div> 
                   
          <div className='user-body'>

            <div className='btn-con-label'>
              <h3>ESTADO DEL LABORATORIO</h3>
              {btnVisorDeEstados}
            </div>

            <div className='btn-con-label'>
              <h3>CARGAR ORDEN</h3>
              {btnCargarOrden}
            </div>

            <div className='btn-con-label'>
              <h3>MOSTRAR RESULTADOS</h3>
              {btnMostrarResultados}
            </div>
            
            <div className='btn-con-label'>
              <h3>ESTADÍSTICAS</h3>
              {btnEstadisticas}
            </div>

            <div className='btn-con-label'>
              <h3>DERIVACIONES</h3>
              {/* <OverlayTrigger placement='bottom' overlay={ingresoMedico || !ingresoBioquímico ? tooltipSinPermisos : <></>}> */}
                {btnDerivaciones}
              {/* </OverlayTrigger> */}
            </div>

          </div>

          <div className='user-foot'>
            <Marquesina/>
          </div>      

        </div>
      )
  };
};

export default Opciones;