import React, { useEffect } from 'react'
import { useStore } from '../store/store'
import { useStoreBuscador } from '../store/storeBuscador'
import { Navigate, useNavigate } from 'react-router-dom'
import { NombreUsuario } from '../components/NombreUsuario'
import { BtnLogout } from '../components/BtnLogout'
import Buscador from '../components/Buscador'
import Marquesina from '../components/Marquesina'
//import GraficosEstadisticas from '../components/GraficosEstadisticas'
import GraficosEstados from '../components/GraficosEstados'
import { Button } from 'react-bootstrap'

const Estadisticas = () => {

  //Estados globales traidos del store
  const userPermisos = useStore((state)=> state.userPermisos)
  const laboNombre = useStore((state) => state.laboNombre)
  const result = useStoreBuscador((state) => state.result)
  const setResult = useStoreBuscador((state) => state.setResult)
  const setPaginaActual = useStoreBuscador((state) => state.setPaginaActual)

  function leerPermisos (idP){
    const permisoId = userPermisos.find((e) => e === idP)
    return permisoId
  }

  const navigate = useNavigate()
  function volverOpciones(){
    navigate("/opciones")
  }

  useEffect(() => {
    setPaginaActual('estadisticas')
    setResult(null)
  }, [])

  if (userPermisos.find((e) => e === 113)) {
    return <Navigate to='/opciones' replace />
  } else {
    return (
      <div style={{overflow:'hidden'}}>
        <div className="first-line">
          <Button variant="outline-secondary" onClick={() => volverOpciones()} id='btnVolver'>
            <img alt='volver' src='./Imagenes/anterior.png' />
          </Button>
          <img className='laboratorio' src='./Imagenes/estadisticos.png' alt="icon"/>
          <p className='labName'>{laboNombre} - Estadísticas</p>
          <NombreUsuario />
        </div>

        <BtnLogout />

        <Buscador envioDeEntradas={setResult} envioPermisos={leerPermisos}/>

        {/* <GraficosEstadisticas /> */}

        <GraficosEstados entradas={result} envioPermisos={leerPermisos} />

        <div className='marquesina'>
          <Marquesina />
        </div>

      </div>
    )
  }
}

export default Estadisticas