import React, { useState, useEffect } from 'react';
import { Table, Popover, Alert, OverlayTrigger, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import '../Styles/componentsStyles/detalle.css'
//import Marquesina from './Marquesina';
import { useStore } from '../store/store';
import { useStoreBuscador } from '../store/storeBuscador';
import {differenceInYears} from 'date-fns'

//entradaU es UNA entrada seleccionada en tablaResultados
function Detalle({ entradaU, mostrar, handleClose, hideModal, envioPermisos }) {
  const ip = process.env.REACT_APP_IP_API
  const port = process.env.REACT_APP_PORT_API

  //Estado global traido del store
  const idsLaborat = useStore((state) => state.idsLaborat)
  const isLoading = useStoreBuscador((state) => state.isLoading)
  const setIsLoading = useStoreBuscador((state) => state.setIsLoading)

  //botones mostrar/ocultar columna comentarios
  //const [mostrarComentarios, setMostrarComentarios] = useState(true);
  //Mostrar alerta al guardar desde entrada elegida
  const [showGuardado, setShowGuardado] = useState(false)
  //Setea que el modal sea fullscreen
  const [fullscreen] = useState(true);
  //estado que alamacena los datos que trae el fetchAServer
  const [entradaElegida, setEntradaElegida] = useState([])
  //Array con fechas y resultados anteriores de un analisis, seteado en fetchresultadosAnteriores
  const [resultadosAnteriores, setResultadosAnteriores] = useState([])
  //almacena el string de resultados anteriores y los muestra en celda o popover dependiendo el largo
  const [btnResAnt, setBtnResAnt] = useState("")
  //almacena el string concatenado que trae la api con los valores de referencia
  const [btnValRef, setBtnValRef] = useState("")
  //Contiene la ubicacion del protocolo a imprimir
  const [protocolo, setProtocolo] = useState("")
  //Deshabilita el boton Ver Protocolo, en caso de que no exista protocolo para esa entrada en la BBDD
  const [disabled, setDisabled] = useState(false)
  //
  const [checkNum,setCheckNum] = useState(0)
  
  
  //parametros que se enviaran a la API para consultar UNA entrada
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: entradaU && JSON.stringify({
      unaEntrada: entradaU.entrada,
      rango: entradaU.rango,
      fechaInicial: entradaU.fecha.slice(0, 10),
      fechaFinal: entradaU.fecha.slice(0, 10),
      laboId: idsLaborat[0],
      grupoLaboId: idsLaborat[1],
      visibles: envioPermisos(113) ? 113 : 114
    })
  };
  //convierte formato YYYY/MM/DD a DD-MM-YYYY
  function fechaTabla(fecha) {
    let year = fecha.slice(0, 4)
    let month = fecha.slice(5, 7)
    let day = fecha.slice(8, 10)
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    let fechaFormateada = (day + "-" + month + "-" + year)
    return fechaFormateada
  }
  //consulta a la API por todos los analisis de UNA entrada, luego los guarda en entradaElegida
  function fetchAserver() {
    fetch(`http://${ip}:${port}/buscador/unaEntrada`, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        setEntradaElegida(data);
        setIsLoading(false)
        // Map que por cada análisis realiza un fetch a resultados anteriores
        Promise.all(data?.map((item, index) =>
          fetchResultadosAnteriores(item.id, item.id_padre_hijo, index)
        ))
          .then((resultados) => {
            setResultadosAnteriores(resultados);
            // setIsLoading(false)
          })
          .catch(() => {
            console.log("La petición falló en fetchResultadosAnteriores");
          });
      })
      .catch(() => {
        console.log("La petición falló");
      });
  }
  //consulta a la API por analisis de UNA entrada, recibe como params rango e idPh, sacados del analisis clickeado
  function fetchResultadosAnteriores(id, idPh, index) {
    const requestHistorial = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fechaEntrada: entradaU.fecha.slice(0, 10),
        //allIds : idsAnalisis, reemplazo de id y idAnalisis
        id: id,
        idAnalisis: idPh,
        nroDoc: entradaU.nro_doc,
        laboId: idsLaborat[0],
        grupoLaboId: idsLaborat[1]
      })
    };
    return fetch(`http://${ip}:${port}/buscador/resultadosAnteriores`, requestHistorial)
      .then((resp) => resp.json())
      .then((data2) => {
        // console.log('despues adentro')        
        return data2;
      })
      .catch(() => {
        return { data: 111, index };
      });
  } 

  //Funcion que realiza la consulta a la api por el nombre del protocolo correspondiente a la entrada elegida
  function verProtocolo() {
    const request = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: entradaU && JSON.stringify({
        fechaEntrada: entradaU.fecha.slice(0, 10),
        nroEntrada: entradaU.entrada,
        rangoID: entradaU.rango_id,
        laboId: entradaU.laboratorio_id,
        grupoLaboId: entradaU.grupo_laboratorio_id
      })
    };
    fetch(`http://${ip}:${port}/buscador/protocolos`, request)
      .then((resp) => resp.json())
      .then((data) => {
        setProtocolo(data)
        //si tiene tiene oculto los resultados no firmados (115) y la entrada no esta firmada, se deshabilita el boton
        envioPermisos(115) && (entradaU.firmado === 0 || null) ?  setDisabled(true) : setDisabled(false)
      })
      .catch((r) => {
        setProtocolo(111)
        setDisabled(true)
        console.log("La peticion fallo en verProtocolo / No hay protocolo para la entrada seleccionada")
      })
  }
  //Fetch para guardar los analisis validados
  function guardarValidados(e) {
    e.preventDefault()
    const aGuardar = entradaElegida?.map(
      obj =>{
      return {
        id : obj.id,
        comentarios : obj.comentarios,
        validado : Number(obj.validado),
        resultado : obj.resultado
      }
      }
    )
    const req = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(aGuardar)
    };
    fetch(`http://${ip}:${port}/orbital/guardar`, req)
      .then((resp) => resp.json())
      .then((data) => {
        console.log("Analisis validados")
      })
      .catch(() => {
        console.log("Fallo el envio de analisis a validar");
      });
    console.log(req);
  }
  //Permite cambiar los checkbox para validar o no los analisis 
  //Recibo el idAe del analisis como param
  const handleOnChange = (id) => {
    !envioPermisos(113) &&
    setEntradaElegida(entradaElegida?.map( (e)=>{
      if (e.id === id){
        return {...e, validado: !e.validado} }  
      return {...e}
    }))
  };
  //Boton toggle Validar todos
  const handleSelectAll = () => {
    setEntradaElegida(entradaElegida?.map( (e)=>{
      //if (e.padre_id === null && e.resultado === null ){ // 
        if ((e.resultado === null && e.comentarios === null) || e.anulado_logico === 1 || (e.tiporesultado === 5 && e.comentarios === null)){
        return {...e, validado : 0}
      }
      //cambia el valor por defecto
      checkNum === 1 ? setCheckNum(0) : setCheckNum(1)
      return {...e, validado : checkNum } 
    }))
  };
  //funcion que recibe el idAe y el comentario ingresado en el input
  const handleComentarios = (id, comentario)=>{
    setEntradaElegida(entradaElegida?.map( (e)=>{
      if (e.id === id){
        return {...e, comentarios : comentario} }  
      return {...e} 
    }))
  }

  //funcion que recibe el idAe y el resultado ingresado en el input
  const handleResultados = (id, resultado)=>{
    setEntradaElegida(entradaElegida?.map( (e)=>{
      if (e.id === id){
        return {...e, resultado : resultado} }  
      return {...e} 
    }))
  }
  //Boton toggle Ocultar/mostrar columna
  /* const toggleComentarios = () => {
    setMostrarComentarios(!mostrarComentarios);
  }; */
  //Boton que muestra los valores de referencia en caso de ser largos
  const popValRef = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Valores de referencia</Popover.Header>
      <Popover.Body>
        {btnValRef.split("|")?.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>))}
      </Popover.Body>
    </Popover>
  );
  
  //Boton que muestra los resultados anteriores en caso de ser largos
  const popResultadosAnteriores = (
    <Popover id="popover-basic">
      <Popover.Body>
        {btnResAnt}
      </Popover.Body>
    </Popover>
  );
 //Boton que muestra protocolo de cada entrada, si existe protocolo para dicha entrada 
  const btnProtocolo = (
    <Button id= "btnProtocolo" 
    disabled={disabled} 
    onClick={() => {
      const byteCharacters = atob(protocolo); //atob decodifica archivos en base64
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i); //charCodeAt(devuelve valor unicode del  pdf decodificado  )
      }
      const byteArray = new Uint8Array(byteNumbers);
      const file = new Blob([byteArray], {type: 'application/pdf'});
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }}
    variant="secondary" 
    className='btnsMod'>
      Ver Protocolo
    </Button>
  );
 //Boton Guardar (muestra alerta 'DATOS GUARDADOS')
  const mostrarAlerta = () => {
    setShowGuardado(true);
    setTimeout(() => {
      setShowGuardado(false);
    }, 2500);
  };

  //Verifica 1ro si el user tiene prohibido ver res sin firmar, en caso de que no sea asi, continua verificando si es medico
  function checkPermisos (validado, resultado, firmado){
    if (envioPermisos(115) && firmado === 0) {
      return "" //se oculta porque no esta firmado
    }else{
      if(envioPermisos(113) && validado === 0) {
        return "" //se oculta porque es medico y no esta validado
      }else{return resultado} //si no es medico, ni tiene oculto los firmados, se ve el resultado
    }
  }

  //Calcula edad de paciente, restando el dia de hoy menos su fecha de nacimiento. Con date-fns
  function calcEdad(fechaNacimiento){
    const HOY = new Date()
    const nacimiento = new Date(fechaNacimiento)
    let edad = differenceInYears(HOY, nacimiento) 
    return edad
  }


  //verifica que tipo de resultado tiene el analisis y asigna el tipo de input que lo contiene
  /* function tipoResultado(tipo){
    switch (tipo) {
      case 0:
        return"text";
      case 1:
        return "number";
      case 2: //tipo opciones
        return "text";
      case 3: //resultado tipo formula
        return "text";
      case 4:
        return "text"
      default: //sin resultado
        return "text ";
    }
  } */

  useEffect(() => {
    if (hideModal) { setShowGuardado(false); }
    fetchAserver()
    verProtocolo();
    setIsLoading(true)
    setResultadosAnteriores([])
    return (() => {
        //setCheckedState(handleOnChange())
        setCheckNum(0)
        setEntradaElegida([])
        setResultadosAnteriores([])
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entradaU, setEntradaElegida, hideModal])
  return (
    <>{entradaU &&   /* Si la prop entrada no es undefinded, muestra los analisis de la misma */
      <Modal id="modal-detalle" className="modal" onHide={hideModal} show={mostrar} keyboard={false} backdrop="static" fullscreen={fullscreen}  >
        <Modal.Header>
          <Modal.Title style={{ display: "flex", justifyContent: "space-evenly" }}>
              <div style={{marginTop: '1vh'}}>
                <Button variant="outline-secondary" onClick={() => {
                  setEntradaElegida([])
                  handleClose()
                }} className='btnsMod' id='btnVolver'>
                  {/* Volver */}
                  <img alt='volver' src='./Imagenes/anterior.png' />
                </Button>
              </div>
              <img className='paciente' src="./Imagenes/pacienteIcono.png" alt="icon" />
              <div className='datos'>
                <p>Paciente : {entradaU.apellido + ", " + entradaU.nombres} </p>
                <p>Documento: {entradaU.nro_doc.toLocaleString('es')}</p>
                {/* <p>Edad:  {entradaU.edad < 120 ? entradaU.edad + " años" : '-'}</p> */}
                <p>Edad:  {calcEdad(entradaU.fecha_nacimiento) < 120 ? calcEdad(entradaU.fecha_nacimiento) + " años" : " - " }</p>
                <p>Entrada {entradaU.rango + entradaU.entrada} del  {fechaTabla(entradaU.fecha).slice(0, 10)} </p>
              </div>
          </Modal.Title>
          {showGuardado && <Alert className='datos-guardados' show={showGuardado} variant="success">
            <p>DATOS GUARDADOS (aguarde unos segundos para cerrar esa ventana)</p></Alert>}
        </Modal.Header>
        <Modal.Body style={{overflow: isLoading && 'hidden'}}>
          <form id="form-datos" onSubmit={(e) => guardarValidados(e)}>
            <Table bordered id="tablaAnalisis">
              <thead>
                <tr id="tablaHeader">
                  <th id='analisis'>Analisis</th>
                  <th>Resultado</th>
                  <th>Unidad</th>
                  <th>Val. Ref.</th>
                  <th className='validar'>Validar</th>
                  <th>Comentarios</th>
                  <th id='th-res-ant'>Resultados anteriores</th>
                  {/* <th>Repetir Analisis</th> */}
                </tr>
              </thead>
              <tbody className="resultados">
                {/* Si la prop entradaElegida no es undefinded, entra en el map que trae todos que cumplan con los criterios del buscador */}
                {(entradaElegida.length > 0) && <>
                  {entradaElegida?.map((item, index) => (
                    
                    <tr key={index} className={index} style={item.anulado_logico === 1 ? {backgroundColor:'#EBEBEB', color:'#A5A5A5'}: {backgroundColor:'white'}}>

                      {/* Analisis */}
                      {item.tipo_resultado === 5 ? <td id='analisis' className={item.padre_id === null ? "tipo-1" : "tipo-"+item.tipo_orden + (
                        item.tiene_hijos === 1 ? "1" : ""
                      )}>{item.analisis}</td>
                      : <td id='analisis'  className={"tipo-"+ item.tipo}>{item.analisis}</td>}

                      {/* Resultado --- si Es medico  (permiso 113) y validado es 0 se oculta el resultado*/}
                      <td id={index} className='td-resultado'>
                        <input className='inputResultado' 
                          title = {checkPermisos(item.validado, item.resultado, entradaU.firmado)} 
                          id={"resultado"+index}                        
                          /* defaultValue={item.tipo_resultado === 1 ? parseFloat(checkPermisos(item.validado, item.resultado, entradaU.firmado).replaceAll(',', '.'))  
                            : checkPermisos(item.validado, item.resultado, entradaU.firmado)}
                          type= {tipoResultado(item.tipo_resultado)} */
                          defaultValue= {checkPermisos(item.validado, item.resultado, entradaU.firmado)}
                          disabled = {(envioPermisos(113) || item.tipo_resultado === 5 || item.anulado_logico === 1) && true}
                          onChange={(e)=>handleResultados(item.id, e.target.value)} 
                          onKeyDown={(e) => {e.key === 'Enter' && e.preventDefault()}}
                        />
                      </td> 

                      {/*Unidades --- si Es medico  (permiso 113) y validado es 0 se oculta  */}  
                      {<td id={index} className='td-unidad'>
                        <p>{(envioPermisos(113) && item.validado === 0) ? "" : item.unidad}</p>
                      </td> }
                    
                      {/* Valores de referencias */}
                      <td id={index} className='td-valref'>
                        {item.resultado && item.valores_referencia !== null && item.valores_referencia.length > 17 ? 
                          <OverlayTrigger rootClose={true} trigger="click" placement='right' overlay={popValRef}>
                            <Button
                              onClick={() => {setBtnValRef(item.valores_referencia)}}> +
                            </Button>
                          </OverlayTrigger>
                        : <p>{item.resultado && item.valores_referencia}</p>}
                      </td> 
                      
                      {/* Checks analisis validados */}
                      <td id={index} onClick={()=>(handleOnChange(item.id))} className='validar'>
                        {(item.resultado !=null && item.anulado_logico === 0 && item.tipo_resultado !== 5) && <>
                        {/* <p className='validado'>{item.validado}</p> */}
                          <input 
                            type='checkbox' 
                            name={item.id} 
                            className='checkValidar' 
                            readOnly 
                            checked = {item.validado} 
                            /* onChange={()=>{handleOnChange(item.id)}} */  >
                          </input> 
                        </>}
                      </td> 

                      {/* Comentarios */}
                      <td id={index} className='td-comentarios'>
                        <input className='inputComentario'  
                          title={item.comentarios}
                          disabled = {item.anulado_logico === 1}
                          onChange={(e)=>handleComentarios(item.id, e.target.value)} 
                          onKeyDown={(e) => {e.key === 'Enter' && e.preventDefault()}}
                          id={"comentario"+index} 
                          defaultValue={item.comentarios === 'null' ? '' : item.comentarios }/>
                      </td>
                      
                      {/* Resultados anteriores */}
                      {item.resultado && resultadosAnteriores[index] !== undefined ? <td id={index} className='td-box-anteriores'>
                        {resultadosAnteriores[index].slice(-5)?.map((i)=> ( 
                          <span id={index} className='celda-anteriores'>
                            {i.resultado !== null && i.resultado.length > 10 ?
                            <OverlayTrigger rootClose={true} trigger="click" placement='top' overlay={popResultadosAnteriores}>
                              <Button id={'btnResAnt'+index}
                                className='resAnteriores'
                                onClick={() => {setBtnResAnt(i.resultado)}}> +
                              </Button>
                            </OverlayTrigger> :
                            <p id={'pResAnt'+index} className='resAnteriores'>{i.resultado ? (i.resultado +" "+ item.unidad) : " - " }</p>}
                            <p id={'fResAnt'+index} className='resAnteriores'>{fechaTabla(i.entrada_fecha).slice(0,10)}</p>
                          </span>
                        ))}
                      </td> : ''/* isLoading && item.resultado && <Spinner animation='border' variant='dark'/> */} 
                      
                      {/*Boton repetir analisis*/}
                      {/* <td id="td-repetir" className={index}>
                        {item.resultado && <Button id="boton-repetir" onClick={()=>{valRef(item.id_padre_hijo)}}  variant="success"> Repetir analisis </Button>}
                      {/*</td> */}
                    </tr>))}</>
                  }
              </tbody>
            </Table>
          </form>
          {isLoading && <div className="loader-detalle"><Spinner animation="border" /></div>}
        </Modal.Body>
        <Modal.Footer className='detalle-foot'>
          {/* <Marquesina/> */}
          <div className="btnsDetalle">
            <Button id="toggle-check" type="checkbox" className='btnsMod'
              onClick={handleSelectAll}
              variant="primary"
              disabled={envioPermisos(113) && true}>
              Validar Todos
            </Button>
            <Button form="form-datos" type='submit' onClick={mostrarAlerta} variant="success" className='btnsMod' disabled={envioPermisos(113) && true}>
              Guardar
            </Button>
            {btnProtocolo}
          </div>
        </Modal.Footer>
      </Modal>
    }
    </>
  );
}
export default Detalle;