import React, { useState } from "react";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import NuevaDerivacion from "./NuevaDerivacion";
import AjustesDerivacion from "./AjustesDerivacion";

const Menu = () => {
  //Manejar el estado mostrar/ocultar del menu
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  //Manejar el estado mostrar/ocultar del modal Nueva Derivacion
  const [showModalNueva, setShowModalNueva] = useState(false);
  //Manejar el estado mostrar/ocultar del modal Ajustes Derivacion
  const [showModalAjustes, setShowModalAjustes] = useState(false);

  //Mostrar/ Ocultar Menu
  const handleClose = () => setShowOffcanvas(false);
  const handleShow = () => setShowOffcanvas(true);

  //Boton que muestra el modal para crear una nueva derivación
  const modalNuevaDeriv = (
    <Modal show={showModalNueva} backdrop={true} onHide={() => setShowModalNueva(false)} keyboard={false}>
      <div className='modal-deriv'>
        <NuevaDerivacion />
      </div>
    </Modal>
  );

  //Boton que muestra el modal para configurar los ajustes de derivaciones
  const modalAjustesDeriv = (
    <Modal show={showModalAjustes} backdrop={true} onHide={() => setShowModalAjustes(false)} keyboard={false} >
      <div className='modal-deriv'>
        <AjustesDerivacion />
      </div>
    </Modal>
  );

  return (
    <>
      <Button className="btn-menu" variant="secondary" onClick={handleShow}>
        <img className="img-menu" src='./Imagenes/menuDerivaciones.png' alt='menu'/></Button>

      <Offcanvas show={showOffcanvas} onHide={handleClose}>
        <Offcanvas.Header style={{display:'flex', justifyContent:'center'}}>
          <Offcanvas.Title style={{fontSize:'3.5vh', fontWeight:'bold'}}>DERIVACIONES</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className='menu-deriv'>

            <li>
              <div>
                <Button className='btn-menu-list' variant="secondary" onClick={()=>setShowModalNueva(true)}>
                  <img src="./Imagenes/nueva.png" className="img-menu" alt="nueva"/> Nueva </Button>
                {modalNuevaDeriv}
              </div>
            </li>

            <br />

            <li>
              <div>
                <Button className='btn-menu-list' variant="secondary" onClick={()=>setShowModalAjustes(true)}> 
                  <img src="./Imagenes/ajustes.png" className="img-menu" alt="ajustes"/> Ajustes </Button>
                {modalAjustesDeriv}
              </div>
            </li>

          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Menu;
