import { create } from 'zustand'
import { formatISO } from 'date-fns'

const hoy = formatISO(new Date(), {representation:'date'})

export const useStoreBuscador = create((set) => ({
  //Estado inicial (vacios) de los params a enviar a la API
  entrada: {
    tipoDoc: "",
    documento: "",
    apellido: "",
    nombre: "",
    //entrada: "",
    //procedencia: "",
    servicio: "",
    fechaInicial: hoy,
    fechaFinal: hoy,
    box: "",
    equipos:"",
    estado: "Todos"
  },
  setEntrada: (data) => set((state) => ({ entrada: data })),

  //Estado que se setea en el fetch a server al renderizarse spinners de carga
  isLoading: false,
  setIsLoading: (data) => set((state) => ({isLoading: data})),

  // equipos: '',
  // setEquipos: (data) => set((state) => ({equipos: data})),

  /* setResult obtiene el resultado de la busqueda parametrizada en Buscador, el cual lo recibe el componente padre
  y se lo envia a TablaResultados y a GraficosEstados con result como prop */
  result : null,
  setResult : (data) => set((state) => ({result: data})),

  //Define si se muestra o no un input dependiendo de la pagina donde se muestra el buscador (no utilizado)
  paginaActual : '',
  setPaginaActual : (data) => set((state) => ({paginaActual: data})),

  //Al re-renderizarse la pagina, mantiene los parametros ingresados, y los trae por cada modificacion 
  mantenerCambios: (e) => {
    e && e.preventDefault()
    e && set((state) => ({
      entrada: {
        ...state.entrada,
        [e.target.name]: e.target.value,
      },
    }));
  },
  
  //Se pasa al useEffect al cerrar sesión y al volver a la ventana de Opciones para que se vacien los parametros de búsqueda
  vaciarBusqueda: () => set(() => ({entrada: {
    tipoDoc: "",
    documento: "",
    apellido: "",
    nombre: "",
    //entrada: "",
    //procedencia: "",
    servicio: "",
    fechaInicial: hoy,
    fechaFinal: hoy,
    box: "",
    estado: "Todos"
  }})),
}))