import React from "react";
import Marquee from "react-fast-marquee";

const Marquesina = () => {
  

  return (
    <Marquee
      speed={80}
      pauseOnHover
      gradient= {false}
    >

      {/* <span>Orbital, es un sistema de gestión integral que resuelve las necesidades de los Laboratorios Bioquímicos, 
        optimizando la performance en todas las fases, pre-analítica, analítica y post-analítica.
      </span> */}
      <span>*** Muchas gracias por utilizar los servicios de Orbital Cloud ***</span>

    </Marquee>
    
  )

}

export default Marquesina;