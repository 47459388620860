import './Styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Visor from "./Pages/Visor";
import Login from './Pages/Login';
import Opciones from './Pages/Opciones';
import Derivaciones from './Pages/Derivaciones';
import { EstadosLab } from './Pages/EstadosLab';
import ErrorPage from './Pages/errorPage';
import { useStore } from './store/store';
import Estadisticas from './Pages/Estadisticas';

function App() {
  const isLogged = useStore((state) => state.isLogged) //Estado que determina si el usuario se logueo o no (se setea en Login y en BtnLogout)
  return (
    <Router>
      <Routes>
        <Route index element={isLogged ? <Navigate to="/opciones" /> : <Login />} />

        <Route path='opciones' element={isLogged ? <Opciones /> : <Navigate to="/" />} />

        <Route path='visor' element={isLogged ? <Visor /> : <Navigate to="/" />} />

        <Route path='estadosLab' element={isLogged ? <EstadosLab /> : <Navigate to="/" />} />

        <Route path='estadisticas' element={isLogged ? <Estadisticas /> : <Navigate to="/" />} />

        <Route path='derivaciones' element={isLogged ? <Derivaciones /> : <Navigate to="/" />} />

        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}
export default App;