import React from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Pie, Bar } from 'react-chartjs-2'
import { useNavigate } from 'react-router-dom';
import { Accordion } from 'react-bootstrap';
import { useStoreBuscador } from '../store/storeBuscador';
import (".././Styles/estadosLab.css");

const GraficosEstados = ({entradas}) => {

    //Estados globales guardados en el store
    const paginaActual = useStoreBuscador((state) => state.paginaActual)
    const equipos = useStoreBuscador((state) => state.equipos)

    //Se utilizo la librería Chart.js para agregar los gráficos de estado del laboratorio
    ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title, ChartDataLabels);
    //Al hacer click determinados gráficos, se puede ingresar al visor de entradas
    const navigate = useNavigate()
    function navVisor(){navigate("/visor")}

    //Inicializacion de variables que van a rellenar los graficos
    let totalPendientesValidar = 0
    let totalCompletos = 0
    let totalResultadosYPendientes = 0
    let totalPendientes = 0

    //---------------SERVICIOS-------------------//
    //Se crea el array que va a traer cada una de las servicios (eje Y del grafico)
    const claveServicio = 'servicio'
    
    const serviciosUnicos = new Set()
    
    entradas &&
    entradas.forEach(item => {
        serviciosUnicos.add(item[claveServicio])
    })
    
    const arrayLabelsServicios = [...serviciosUnicos]
    
    //Sumatoria de entradas para rellenar el grafico de Servicios segun lo ingresado en buscador
    // Crear un objeto para almacenar los recuentos por servicio y estado
    const ObjServicioYEstado = {};

    // Iterar sobre cada entrada en el array
    entradas &&
    entradas.forEach(e => {
        // Obtener el servicio y el estado de la entrada actual
        const servicio = e.servicio;
        const estado = e.Estado;

        // Verificar si ya existe una entrada para el servicio en el objeto ObjServicioYEstado
        if (!ObjServicioYEstado[servicio]) {
            ObjServicioYEstado[servicio] = {};
        }

        // Verificar el estado y actualizar el contador correspondiente
        switch (estado) {
            case 'Con Pendientes de Validar':
                ObjServicioYEstado[servicio].totalPendientesValidar = (ObjServicioYEstado[servicio].totalPendientesValidar || 0) + 1;
                break;
            case 'Completos':
                ObjServicioYEstado[servicio].totalCompletos = (ObjServicioYEstado[servicio].totalCompletos || 0) + 1;
                break;
            case 'Con Resultados y Pendientes':
                ObjServicioYEstado[servicio].totalResultadosYPendientes = (ObjServicioYEstado[servicio].totalResultadosYPendientes || 0) + 1;
                break;
            case 'Pendientes':
                ObjServicioYEstado[servicio].totalPendientes = (ObjServicioYEstado[servicio].totalPendientes || 0) + 1;
                break;
            default:
                break;
        }
        });

    //Conversion de ObjServicioYEstado (Objeto de objetos) a arrayServicios (array de objetos)
    //Buscar la manera de que se cree inicialmente un array de objetos
    const arrayServicios = Object.values(ObjServicioYEstado)

    // Crear arrays para cada estado
    const PendientesValidarServicios = [];
    const CompletosServicios = [];
    const ResultadosYPendientesServicios = [];
    const PendientesServicios = [];

    // Iterar sobre los valores y agregar las cantidades correspondientes a cada array
    arrayServicios.forEach(recuentos => {
        PendientesValidarServicios.push(recuentos.totalPendientesValidar || 0);
        CompletosServicios.push(recuentos.totalCompletos || 0);
        ResultadosYPendientesServicios.push(recuentos.totalResultadosYPendientes || 0);
        PendientesServicios.push(recuentos.totalPendientes || 0);
    });

    //---------------SECCIONES-------------------//
    //Se crea el array que va a traer cada una de las secciones (eje Y del grafico)
    const claveBoxes = 'boxes'
    
    const seccionesUnicas = new Set()
    
    entradas &&
    entradas.forEach(item => {
        seccionesUnicas.add(item[claveBoxes])
    })
    
    const arrayLabelsSecciones = [...seccionesUnicas]
    
    //Sumatoria de entradas para rellenar el grafico de Secciones segun lo ingresado en buscador
    // Crear un objeto para almacenar los recuentos por servicio y estado
    const ObjSeccionYEstado = {};

    // Iterar sobre cada entrada en el array
    entradas &&
    entradas.forEach(e => {
        // Obtener el servicio y el estado de la entrada actual
        const seccion = e.boxes;
        const estado = e.Estado;

        // Verificar si ya existe una entrada para el servicio en el objeto ObjServicioYEstado
        if (!ObjSeccionYEstado[seccion]) {
            ObjSeccionYEstado[seccion] = {};
        }

        // Verificar el estado y actualizar el contador correspondiente
        switch (estado) {
            case 'Con Pendientes de Validar':
                ObjSeccionYEstado[seccion].totalPendientesValidar = (ObjSeccionYEstado[seccion].totalPendientesValidar || 0) + 1;
                break;
            case 'Completos':
                ObjSeccionYEstado[seccion].totalCompletos = (ObjSeccionYEstado[seccion].totalCompletos || 0) + 1;
                break;
            case 'Con Resultados y Pendientes':
                ObjSeccionYEstado[seccion].totalResultadosYPendientes = (ObjSeccionYEstado[seccion].totalResultadosYPendientes || 0) + 1;
                break;
            case 'Pendientes':
                ObjSeccionYEstado[seccion].totalPendientes = (ObjSeccionYEstado[seccion].totalPendientes || 0) + 1;
                break;
            default:
                break;
        }
    });

    const arraySecciones = Object.values(ObjSeccionYEstado)

    // Crear arrays para cada estado
    const PendientesValidarSecciones = [];
    const CompletosSecciones = [];
    const ResultadosYPendientesSecciones = [];
    const PendientesSecciones = [];

    // Iterar sobre los valores y agregar las cantidades correspondientes a cada array
    arraySecciones.forEach(recuentos => {
        PendientesValidarSecciones.push(recuentos.totalPendientesValidar || 0);
        CompletosSecciones.push(recuentos.totalCompletos || 0);
        ResultadosYPendientesSecciones.push(recuentos.totalResultadosYPendientes || 0);
        PendientesSecciones.push(recuentos.totalPendientes || 0);
    });

    //---------------EQUIPOS---------------------//
    const claveEquipos = 'equipos'

    const equiposUnicos = new Set()

    equipos &&
    equipos.forEach(item => {
        equiposUnicos.add(item[claveEquipos])
    })
    /* const arrayLabelsEquipos = [...equiposUnicos]
    console.log('equipos:',arrayLabelsEquipos) */

    
    //---------------PACIENTES-------------------//
    //Sumatoria de entradas para rellenar el grafico de Pacientes segun lo ingresado en buscador
    entradas &&
    entradas.forEach (e => {
        switch (e.Estado) {
            case 'Con Pendientes de Validar':
                totalPendientesValidar++;
                break;
            case 'Completos':
                totalCompletos++
                break;
            case 'Con Resultados y Pendientes':
                totalResultadosYPendientes++
                break;
            case 'Pendientes':
                totalPendientes++
                break;
            default:
                break
        } 
    })

    let datosTortaPacientes = [totalPendientes, totalPendientesValidar, totalResultadosYPendientes, totalCompletos]

    //PROPS DE CADA UNO DE LOS CHART
    //Datos que recibe grafico de torta de Pacientes
    const dataPacientes = {
        labels: [
            'Pendientes',
            'Con Pendientes de Validar',
            'Con Resultados y Pendientes',
            'Completos'
        ],
        datasets: [
            {
                label: 'Ordenes',
                data: datosTortaPacientes,
                backgroundColor: [
                    '#E03128',
                    '#E07D23',
                    '#E1BB08',
                    '#24E06A',
                ],
                borderWidth: 1,
            },
        ],
    };

    //Datos que recibe grafico de barras de Servicios
    const dataServicios = {
        labels : arrayLabelsServicios,
        datasets: [
            {
                label: 'Pendientes',
                data: PendientesServicios,
                backgroundColor: '#E03128',
            },
            {
                label: 'Con Pendientes de Validar',
                data: PendientesValidarServicios,
                backgroundColor: '#E07D23',
            },
            {
                label: 'Con Resultados y Pendientes',
                data: ResultadosYPendientesServicios,
                backgroundColor: '#E1BB08',
            },
            {
                label: 'Completos',
                data: CompletosServicios,
                backgroundColor: '#24E06A',
            },
        ],
    };
    
    //Datos que recibe grafico de barras de Secciones
    const dataSecciones = {
        labels : arrayLabelsSecciones,
        datasets: [
            {
                label: 'Pendientes',
                data: PendientesSecciones,
                backgroundColor: '#E03128',
            },
            {
                label: 'Con Pendientes de Validar',
                data: PendientesValidarSecciones,
                backgroundColor: '#E07D23',
            },
            {
                label: 'Con Resultados y Pendientes',
                data: ResultadosYPendientesSecciones,
                backgroundColor: '#E1BB08',
            },
            {
                label: 'Completos',
                data: CompletosSecciones,
                backgroundColor: '#24E06A',
            },
        ],
    };

    /* const dataEquipos = {
        labels: ['Equipo 1', 'Equipo 2', 'Equipo 3', 'Equipo 4'],
        datasets: [
            {
                label: 'Pendientes',
                data: [25,25,25,25],
                backgroundColor: '#E03128',
            },
            {
                label: 'Con Resultado',
                data: [25,25,25,25],
                backgroundColor: '#24E06A',
            },
        ]
    } */

    //Maneja la visualizacion de los graficos de barra
    const BarOptions ={
        indexAxis:'y',
        plugins:{
            legend:{
                display:true,
                position:'bottom',
                labels:{
                    font:{
                        size:17,
                        weight: 'bold'
                    },
                    color: 'black',
                },
            },
            datalabels: {labels: {title: null}}
        },
        responsive: true,
        scales: {
            x: {stacked: true},
            y: {stacked: true,
                ticks: {
                    font: {
                        size: 17,
                        weight: 'bold',
                    },
                    color: 'black'
                },
            },
        },
    }

    //Maneja la visualización de los gráficos de torta
    const PieOptions = {
        plugins:{
            legend: {
                display: true,
                position:'bottom',
                labels:{font:{size:19,weight: 'bold'}, color: 'black'}
            },
            datalabels: {
                formatter: (value) => {
                    return value >= 11  ? value : ''; // Mostrar la etiqueta solo si el valor es mayor a 11
                },
                color: 'white',
                labels: {title:{font:{size: 40}}}
            }
        }
    }

    return (
    <div className='graf-line'>
        <div onClick={paginaActual === 'estadisticas' ? '' : ()=>navVisor()}>
            {paginaActual === 'estadisticas' ? '' : <h2 className='msg-click-grafico'>Acceder al Detalle de Pacientes</h2>}
            <div className='graf-item'> 
                <div style={{display:'grid', alignItems:'center'}}>
                    <h2 className='graf-label'>ORDENES</h2>
                    <div className='total-entradas'> Total: <br/> {entradas && entradas.length}</div>
                </div>
                <Pie className='torta' data={dataPacientes} options={PieOptions}/>
            </div>
        </div>
        <div className='accordion'>
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header className='bar-label'>SERVICIOS</Accordion.Header>
                    <Accordion.Body className='barras'>
                        <h2 className='graf-label'>SERVICIOS</h2>
                        <Bar data={dataServicios} options={BarOptions}/>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header className='bar-label'>SECCIONES</Accordion.Header>
                    <Accordion.Body className='barras'>
                        <h2 className='graf-label'>SECCIONES</h2>
                        <Bar data={dataSecciones} options={BarOptions}/>
                    </Accordion.Body>
                </Accordion.Item>
                {/* <Accordion.Item eventKey="2">
                    <Accordion.Header className='bar-label'>EQUIPOS</Accordion.Header>
                    <Accordion.Body className='barras'>
                        <h2 className='graf-label'>EQUIPOS</h2>
                        <Bar data={dataEquipos} options={BarOptions}/>
                    </Accordion.Body>
                </Accordion.Item> */}
            </Accordion>
        </div>
    </div>
  )
}

export default GraficosEstados