import React from 'react'

const errorPage = () => {
  return (
    <div className='error-page'>
      <img className='error' src="/Imagenes/error404.png" alt='error' />
      <img className='icono' src="/favicon.png" alt="orbital"/>
    </div>
  )
}

export default errorPage