import React, { useEffect } from 'react'
import { Navigate, useNavigate } from 'react-router-dom';
import {NombreUsuario} from '../components/NombreUsuario';
import { BtnLogout } from '../components/BtnLogout';
import Marquesina from '../components/Marquesina';
import Buscador from '../components/Buscador';
import GraficosEstados from '../components/GraficosEstados';
import { useStore } from '../store/store';
import { useStoreBuscador } from '../store/storeBuscador';
import { Button } from 'react-bootstrap';
import (".././Styles/estadosLab.css");

export const EstadosLab = () => {
  //Estados globales traidos del store
  const userPermisos = useStore((state) => state.userPermisos)
  const laboNombre = useStore((state) => state.laboNombre)
  const result = useStoreBuscador((state) => state.result)
  const setResult = useStoreBuscador((state) => state.setResult)
  const setPaginaActual = useStoreBuscador((state) => state.setPaginaActual)
  
  /* Estados, setResult obtiene el resultado de la busqueda parametrizada en Buscador, el cual lo recibe el componente padre
  y se lo envia a TablaResultados con result como prop */
  //const [result, setResult] = useState([])

  function leerPermisos (idP){
    const permisoId = userPermisos.find((e) => e === idP)
    return permisoId
  }

  const navigate = useNavigate()
  function volverOpciones(){
    navigate("/opciones")
  }

  useEffect(() => {
    setPaginaActual('estadosLab')
    setResult(null)
  }, [])

  if (userPermisos.find((e)=> e === 113)){
    return <Navigate to="/opciones" replace />       
  } else {
    return (
      <div style={{overflow:'hidden'}}>
        <div className="first-line">
          <Button variant="outline-secondary" onClick={() => volverOpciones()} id='btnVolver'>
            <img alt='volver' src='./Imagenes/anterior.png' />
          </Button>
          <img className='laboratorio' src='./Imagenes/trabajo-diario.png' alt="icon"/>
          <p className='labName'>{laboNombre} - Estado del Laboratorio</p>
          <NombreUsuario />
        </div>
  
        <BtnLogout />

        <Buscador envioDeEntradas={setResult} envioPermisos={leerPermisos} />

        <GraficosEstados entradas={result} envioPermisos={leerPermisos} />

        <div className='marquesina'>
          <Marquesina />
        </div>
      </div>
    )
  }
}