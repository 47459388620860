import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store/store';
import { useStoreBuscador } from '../store/storeBuscador';

export const BtnLogout = () => {
  //Se redirige a la página "/" que muestra el componente Login
  const handleLogout = useStore((state) => state.handleLogout)
  const vaciarBusqueda = useStoreBuscador((state) => state.vaciarBusqueda)

  const navigate = useNavigate();
  function cerrarSesion() {
    handleLogout()
    vaciarBusqueda()
    navigate("/")
  }
  
  //Cuando el día cambia, se desloguea al usuario
  useEffect(() => {
    const hoy = new Date().toISOString().split('T')[0];

    const dataLocal = localStorage.getItem("userData")
    const dataLocalT = JSON.parse(dataLocal)
    let diaSeparado1 = dataLocalT.state.hoyG.split("-")[2]
    let diaSeparado2 = hoy.split("-")[2]
    if (diaSeparado1 !== diaSeparado2) {
      console.log("La fecha es diferente")
      handleLogout()
      navigate("/")
    }
  })

  return (
    <Button className="logout-container" variant="danger" onClick={() => cerrarSesion()} >Cerrar sesión </Button>
  )
}
