import React, { useEffect, useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { NombreUsuario } from "../NombreUsuario";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store/store";

//envioDeEntradas es el estado que se utiliza para enviar las entradas que coinciden con los parametros de busqueda de Buscador a TablaResultados
const FiltroDerivaciones = ({ filtroDeEntradas }) => {
  //devuelve fecha actual para los inputs de fecha
  const hoy = new Date().toISOString().split('T')[0];
  const laboNombre = useStore((state) => state.laboNombre)

  //Estado que se setea en el fetch a server cuando se carga el spinner de la tabla de resultados de busqueda
  const [isLoading , setIsLoading] = useState(false);
  //Estado inicial (vacios) de los params a enviar a la API
  const [entrada, setEntrada] = useState({
    tipoDoc: "",
    documento: "",
    apellido: "",
    nombre: "",
    //entrada: "",
    //procedencia: "",
    servicio: "",
    fechaInicial: hoy,
    fechaFinal: hoy,
    box: ""
  })

  //Trae sección
  /* function boxDB() {
    fetch("http://localhost:3030/buscador/box", request)
      .then((resp) => resp.json())
      .then((data) => {
        //setbox(data)
        //console.log(data)
      })
      .catch(() => {
        console.log("La peticion fallo en box")
      })
  } */
  /* const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      //tipoDoc: entrada.tipoDoc,
      documento: entrada.documento,
      apellido: entrada.apellido,
      nombre: entrada.nombre,
      //entrada: entrada.entrada,
      //procedencia: entrada.procedencia,
      //servicio: entrada.servicio,
      fechaInicial: entrada.fechaInicial,
      fechaFinal: entrada.fechaFinal,
      //box: entrada.box
    })
  }; */

  //Al re-renderizarse la pagina, mantiene los parametros ingresados, y los trae por cada modificacion 
  function mantenerCambios(e) {
    e.preventDefault();
    setEntrada({
      ...entrada,
      [e.target.name]: e.target.value,
      fechaInicial: document.getElementById('fechaInicial').value,
      fechaFinal: document.getElementById('fechaFinal').value
    })
  }

  function handleChangeFI () {
    document.getElementById("fechaFinal").min = document.getElementById('fechaInicial').value
    setEntrada ({
      ...entrada,
      fechaInicial: document.getElementById('fechaInicial').value
    })
  }

  function handleChangeFF () {
    document.getElementById("fechaInicial").max = document.getElementById('fechaFinal').value
    setEntrada ({
      ...entrada,
      fechaFinal: document.getElementById('fechaFinal').value
    })
  }
  //consulta a la API por x entradas
  function fetchAserver(e) {
    e.preventDefault()
    
    //fetch("http://localhost:3030/buscador", requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        filtroDeEntradas(data)
        setIsLoading(true)
      })
      .catch(() => {
        console.log("La peticion fallo (Filtro de Derivaciones)")
      })
  }

  useEffect(() => {
    //boxDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigate = useNavigate()
  function volverOpciones(){
    navigate("/opciones")
  }

  return (
    <>
      <div className="first-line">
        <Button variant="outline-secondary" onClick={() => volverOpciones()} id='btnVolver'>
          <img alt='volver' src='./Imagenes/anterior.png' />
        </Button>
        <img className='laboratorio' src="./Imagenes/trabajoDerivacion.png" alt="icon"/>
        <p className='labName'>{laboNombre} - Derivaciones</p>
        <NombreUsuario />
      </div>
      <form onSubmit={(e) => 
        {e.preventDefault();
        fetchAserver(e)}}>
        <header className="header-deriv">       
          
            <div className="second-line">

              <div className="second-line-item" >
                <label htmlFor="documento">Numero de documento:</label>
                <input id="documento" name="documento" onChange={mantenerCambios} type="number" placeholder="Documento" ></input>
              </div>

              <div className="second-line-item" >
                <label htmlFor="nombre">Nombre/s:</label>
                <input id="nombre"name="nombre" onChange={mantenerCambios} type="text" placeholder="Nombre del paciente"></input>
              </div>

              <div className="second-line-item" >
                <label htmlFor="apellido">Apellido:</label>
                <input id="apellido" name="apellido" onChange={mantenerCambios} type="text" placeholder="Apellido del paciente"></input>
              </div>

              {/* <div className="second-line-item" >
              <label htmlFor="box">Seccion:</label>
              <select name="box" onChange={mantenerCambios} id="box">
                <option></option>
                {box ? <>
                  {box.map((item, index) => (
                    <option key={index}> {item.nombre} </option>
                  ))}
                </> : <option>--</option>
                }
              </select>
              </div> */}
              <div className="second-line-item" >
                <label htmlFor="fechaInicial">Desde: </label>
                <input id="fechaInicial" name="fechaInicial" onChange={handleChangeFI} value={entrada.fechaInicial} type="date" />
              </div>

            </div>

            <div className="third-line">

              <div className="third-line-item" >
                <label htmlFor="estado">Estado:</label>
                <select name="estado" onChange={mantenerCambios} id="estado">
                  <option></option>
                  <option>Enviado</option>
                  <option>Pendiente</option>
                  <option>Recibido</option>
                  <option>Listo</option>
                  <option>Controlado</option>
                </select>
              </div>

              <div className="third-line-item" >
                <label htmlFor="protocoloID">ID Protocolo:</label>
                <input name="protocoloID" onChange={mantenerCambios} type="text" id="protocoloID"></input>
              </div> 

              {/*----------------ESPACIO VACIO ARBITRARIO PARA PONER OTRO INPUT DE FILTRO----------------*/}
              <div className="third-line-item"></div>

              <div className="second-line-item" >
                <label htmlFor="fechaFinal">Hasta: </label>
                <input name="fechaFinal" onChange={handleChangeFF} max={hoy} id="fechaFinal" value={entrada.fechaFinal} type="date" />
              </div>
            </div>
          
            <div className="btn-filtrar">
                <Button variant="primary" type="submit">Filtrar 🔍</Button>
            </div>
        </header>
        {isLoading && <div className="loader"><Spinner animation="border" /></div>}
      </form>
    </>
  )
}

export default FiltroDerivaciones;
