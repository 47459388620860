import React, { useState, useEffect } from "react";
import { Button , Form, Spinner } from "react-bootstrap";
import { formatISO } from "date-fns";
import { useStore } from "../store/store";
import { useStoreBuscador } from "../store/storeBuscador";

const ip = process.env.REACT_APP_IP_API
const port = process.env.REACT_APP_PORT_API
//envioDeEntradas es el estado que se utiliza para enviar las entradas que coinciden con los parametros de busqueda de Buscador a TablaResultados
const Buscador = ({ envioDeEntradas , envioPermisos }) => {
  //devuelve fecha actual para los inputs de fecha (libreria date-fns)
  const hoy = formatISO(new Date(), {representation: 'date'})

  //Estados globales traidos del store
  const idsLaborat = useStore((state) => state.idsLaborat)
  const medico_id = useStore((state) => state.medico_id)
  //const laboNombre = useStore((state) => state.laboNombre)
  
  //Estados globales traidos del store para cada vez que se utiliza este componente
  const entrada = useStoreBuscador((state) => state.entrada)
  const setEntrada = useStoreBuscador((state) => state.setEntrada)
  const mantenerCambios = useStoreBuscador((state) => state.mantenerCambios)
  const paginaActual = useStoreBuscador((state) => state.paginaActual)
  const isLoading = useStoreBuscador((state) => state.isLoading)
  const setIsLoading = useStoreBuscador((state) => state.setIsLoading)
  

  //Estados que almacenan parametros del buscador que seran enviados a la consulta en el back
  const [tipoDoc, setTipoDoc] = useState([])
  const [servicio, setServicio] = useState([])
  const [box, setbox] = useState([])
  const [equipos, setEquipos] = useState([])

  //Al re-renderizarse la pagina, mantiene los parametros ingresados, y los trae por cada modificacion 
  // function mantenerCambios(e) {
  //   e && e.preventDefault();
  //   e && setEntrada({
  //     ...entrada,
  //     [e.target.name]: e.target.value,
  //     // fechaInicial: document.getElementById('fechaInicial').value,
  //     // fechaFinal: document.getElementById('fechaFinal').value
  //   })
  // }

  //Maneja los cambios en los input de fechas (evita inconvenientes al presionar botones de fecha)
  function handleChangeFI () {
    document.getElementById("fechaFinal").min = document.getElementById('fechaInicial').value
    setEntrada ({
      ...entrada,
      fechaInicial: document.getElementById('fechaInicial').value
    })
  }

  function handleChangeFF () {
    document.getElementById("fechaInicial").max = document.getElementById('fechaFinal').value
    setEntrada ({
      ...entrada,
      fechaFinal: document.getElementById('fechaFinal').value
    })
  }
  
  //parametros que se enviaran a la API, sacados del body - falta agregar idLabo
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      tipoDoc: entrada.tipoDoc,
      documento: entrada.documento,
      apellido: entrada.apellido.trim(),
      nombre: entrada.nombre.trim(),
      entrada: entrada.entrada,
      //procedencia: entrada.procedencia,
      servicio: entrada.servicio,
      fechaInicial: entrada.fechaInicial,
      fechaFinal: entrada.fechaFinal,
      box: entrada.box,
      equipos: entrada.equipos,
      laboId: idsLaborat[0],
      grupoLaboId: idsLaborat[1],
      estado : entrada.estado,
      permiso : envioPermisos(113) ? 113 : 114,
      modoGuardia : envioPermisos(117) ? true : false,
      medicoId : medico_id
    })
  };

  //Metodo para traer tipo de doc, servicios y procedencias
  const request = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      laboId: idsLaborat[0],
      grupoLaboId: idsLaborat[1]
    })
  };

  const requestEquipos = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      fechaInicial: entrada.fechaInicial, 
      fechaFinal: entrada.fechaFinal,
      laboId: idsLaborat[0], 
      grupoLaboId: idsLaborat[1]
    })
  }
  
  //consulta a la API por x entradas
  function fetchAserver(e) {
    e && e.preventDefault()
    
    fetch(`http://${ip}:${port}/buscador`, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        envioDeEntradas(data)
        setIsLoading(false)
        // console.log(data)
      })
      .catch(() => {
        console.log("La peticion fallo (Buscador)")
      })
  }

  //Trae tipos de documentos
  function tipoDocDB() {
    fetch(`http://${ip}:${port}/buscador/tipoDoc`, request)
      .then((resp) => resp.json())
      .then((data) => {
        setTipoDoc(data)
        //console.log(data)
      })
      .catch(() => {
        console.log("La peticion fallo en tipoDoc")
      })
  }

  //Trae servicio
  function servicioDB() {
    fetch(`http://${ip}:${port}/buscador/servicio`, request)
      .then((resp) => resp.json())
      .then((data) => {
        setServicio(data)
        //console.log(data)
      })
      .catch(() => {
        console.log("La peticion fallo en servicio")
      })
  }

  //Trae box
  function boxDB() {
    fetch(`http://${ip}:${port}/buscador/box`, request)
      .then((resp) => resp.json())
      .then((data) => {
        setbox(data)
        //console.log(data)
      })
      .catch(() => {
        console.log("La peticion fallo en box")
      })
  }

  //Trae equipos
  function equiposDB() {
    fetch(`http://${ip}:${port}/buscador/equipos`, requestEquipos)
      .then((resp) => resp.json())
      .then((data) => {
        setEquipos(data)
        //console.log(data)
      })
      .catch(() => {
        console.log("La peticion fallo en equipos")
      })
  }

  //Arma el input de equipos para que no se muestren en caso de repetidos (futuro nuevo endpoint)
  /* const arrayEquiposSinRepetir = [...new Set(equipos.map(item => item.equipo))]

  const inputEquipos = arrayEquiposSinRepetir.map((equipo, index) => (
    <option key={index}>{equipo}</option>
  )) */
  
  //Maneja los onClick de los botones de atajo para fechas
  const handleDateRangeClick = (range) => {
    document.getElementById("fechaFinal").removeAttribute('min')
    document.getElementById('fechaInicial').removeAttribute('max')
    const date = new Date();
    
    if (range === 'today') {
      date.setDate(date.getDate())
    } else if (range === 'week') {
      date.setDate(date.getDate() - 7);
    } else if (range === 'month') {
      date.setDate(date.getDate() - 30);
    } else if (range === 'twoMonths') {
      date.setDate(date.getDate() - 60);
    }
  
    setEntrada({
      ...entrada,
      fechaInicial: formatISO(date, {representation: 'date'}),
      fechaFinal: hoy
    });
  };

  useEffect(() => {
    tipoDocDB()
    servicioDB()
    boxDB()
    equiposDB()
    fetchAserver()
    setIsLoading(true);
    //mantenerCambios()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <header className="header-container">       
        <Form className="form-buscador" 
          onSubmit={((e) => {
            e.preventDefault();
            setIsLoading(true);
            fetchAserver(e);
          })}>

          <div className="second-line">

            {paginaActual !== 'visor' && <img alt="orbitalcloud" src="/Imagenes/Cloud2.png" id="imgEstados"/>}
            {paginaActual === 'visor' && <div className="second-line-item" >
            <label htmlFor="tipoDoc">Tipo de Documento:</label>
            <select name="tipoDoc" id="tipoDoc" value={entrada.tipoDoc} onChange={mantenerCambios}>
              <option></option>
              {tipoDoc ? <>
                {tipoDoc.map((item, index) => (
                  <option title={item.nombre} key={index}> {item.abreviatura} </option>
                ))}
              </> : <option>--</option>
              }
            </select>
            </div>}

            {/*VACIO HASTA ACOMODAR QUE IRIA EN ESTADOS */}
            {/* {paginaActual !== 'visor' && <div className="second-line-item"></div>} */}
            
            {paginaActual === 'visor' && <div className="second-line-item" >
            <label htmlFor="documento">Numero de documento:</label>
            <input name="documento" value={entrada.documento} onChange={mantenerCambios} id="documento" type="number" placeholder="Documento" ></input>
            </div>}

            <div className="second-line-item" >
              <label htmlFor="servicio">Servicio:</label>
              <select name="servicio" value={entrada.servicio} onChange={mantenerCambios} id="servicio">
                <option></option>
                {servicio ? <>
                  {servicio.map((item, index) => (
                    <option key={index}> {item.nombre} </option>
                  ))}
                </> : <option>--</option>
                }
              </select>
            </div>

            {paginaActual !== 'visor' && <div className="third-line-item" >
            <label htmlFor="equipos">Equipos:</label>
            <select name="equipos" value={entrada.equipos} onChange={mantenerCambios} id="equipos" disabled>
              <option></option>
              {equipos ? <>
                {/* {inputEquipos} */}
              </> : <option>--</option>
              }
            </select>
            </div>}
            
            <div className="second-line-item" >
            <label htmlFor="fechaInicial">Desde: </label>
            <input name="fechaInicial" onChange={handleChangeFI} id="fechaInicial" type="date" value={entrada.fechaInicial} />
            </div>

            <div className="second-line-item" >
            <label htmlFor="fechaFinal">Hasta: </label>
            <input name="fechaFinal" onChange={handleChangeFF} max={hoy} id="fechaFinal" type="date" value={entrada.fechaFinal} />
            </div>
            
            {/* <div className="second-line-item" >
            <label htmlFor="entrada">Entrada:</label>
            <input name="entrada" onChange={mantenerCambios} type="number"></input>
            </div> */}
    
          </div>

          <div className="third-line">

            {paginaActual === 'visor' && <div className="third-line-item" >
            <label htmlFor="nombre">Nombre/s:</label>
            <input id="nombre" name="nombre" value={entrada.nombre} onChange={mantenerCambios} type="text" placeholder="Nombre del paciente"></input>
            </div>}

            {paginaActual === 'visor' && <div className="third-line-item" >
            <label htmlFor="apellido">Apellido:</label>
            <input id="apellido" name="apellido" value={entrada.apellido} onChange={mantenerCambios} type="text" placeholder="Apellido del paciente"></input>
            </div>}

            {/*VACIO HASTA ACOMODAR QUE IRIA EN ESTADOS */}
            {paginaActual !== 'visor' && <div className="second-line-item"></div>}

            <div className="third-line-item" >
            <label htmlFor="box">Seccion:</label>
            <select name="box" value={entrada.box} onChange={mantenerCambios} id="box">
              <option></option>
              {box ? <>
                {box.map((item, index) => (
                  <option key={index}> {item.nombre} </option>
                ))}
              </> : <option>--</option>
              }
            </select>
            </div>
            
            {/* <div className="third-line-item" >
            <label htmlFor="procedencia">Procedencia:</label>
            <select name="procedencia" onChange={mantenerCambios} id="procedencia">
              <option></option>
              {procedencia ? <>
                {procedencia.map((item, index) => (
                  <option key={index}> {item.nombre} </option>
                ))}
              </> : <h1>Se rompio procedencia</h1>
              }
            </select>
            </div> */}
          
            <div className="third-line-item">
              <label htmlFor="estado">Estado:</label>
              <select name="estado" value={entrada.estado} onChange={mantenerCambios} id="estado">
                <option>Todos</option>
                <option>Completos</option>
                <option >Con Resultados y Pendientes</option> 
                {!envioPermisos(113) &&
                <option >Con Pendientes de Validar</option>}
                <option >Pendientes</option>
              </select>
            </div>

            {/*VACIO HASTA ACOMODAR QUE IRIA EN ESTADOS */}
            {paginaActual !== 'visor' && <div></div>}
            {/* {paginaActual !== 'visor' && <div className="second-line-item"></div>} */}

            <div className="btnFecha">
              <div className='btnFecha-col'>
                <Button type="button" variant="info" className='fecha' onClick={()=>handleDateRangeClick('today')}>
                    Hoy</Button>
                <Button type="button" variant="info" className='fecha' onClick={()=>handleDateRangeClick('week')}>
                    Semana</Button>
              </div>
              <div className="btnFecha-col">
                <Button type="button" variant="info" className='fecha' onClick={()=>handleDateRangeClick('month')}>
                    Mes</Button>
                <Button type="button" variant="info" className='fecha' onClick={()=>handleDateRangeClick('twoMonths')}>
                    2 meses</Button>
              </div>
            </div>
          </div>
          <div className="btns-form-buscador">
            <Button id="submitButton" variant="outline-primary" type="submit">Buscar 🔍</Button>
            {/* <ToggleButtonGroup type="checkbox">
              <ToggleButton variant="outline-light" id="ver-pacientes">Ver mis pacientes</ToggleButton>
            </ToggleButtonGroup> */}
          </div>
          {isLoading && <div className="loader"><Spinner animation="border" /></div>}
        </Form>
        
      </header>
      
    </>
  )
}

export default Buscador;