import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap';
import { useStore } from '../store/store';
import (".././Styles/login.css")

const ip = process.env.REACT_APP_IP_API
const port = process.env.REACT_APP_PORT_API

const Login = () => {
  const [passwordData, setPasswordData] = useState();

  //Estaados globales almacenados en el store
  const setUserPermisos = useStore((state) => state.setUserPermisos)
  const setIdsLaborat = useStore((state) => state.setIdsLaborat)
  const setIsLogged = useStore((state) => state.setIsLogged)
  const username = useStore((state) => state.username)
  const setUsername = useStore((state) => state.setUsername)
  const nombre = useStore((state) => state.nombre)
  const setNombre = useStore((state) => state.setNombre)
  const setHoyG = useStore((state) => state.setHoyG)
  const setLaboNombre = useStore((state) => state.setLaboNombre)
  const setMedico_id = useStore((state) => state.setMedico_id)

  //Setea el nombre de usuario, el estado logueado y la fecha actual
  const handleLogin = (loggedInUsername) => {
    const hoy = new Date().toISOString().split('T')[0];
    setUsername(loggedInUsername);
    setIsLogged(true)
    setHoyG(hoy)
  }

  //parametros que se enviaran a la API, sacados del body
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      user: username,
      password: passwordData,
    })
  };

  //consulta a la API por x user 
  function handleSubmit (e) {
    e.preventDefault()
    fetch(`http://${ip}:${port}/usuario/login`, requestOptions)
      .then((resp) => resp.json())
      .then((data) => {
        if (data.token) {
          setUserPermisos(data.permisos)
          setIdsLaborat(data.idsLabos[0])
          setNombre(data.nombre)
          setLaboNombre(data.labo_nombre)
          setMedico_id(data.medico_id)
          handleLogin(nombre)
        } else {
          alert("Verifique el usuario o contraseña ingresados")
        }
      })
      .catch(() => {
        alert("La peticion fallo")
      })
  }

  return (
    <div className='login-container'>
      
      <div className='login-col'>
        <Carousel variant="dark" indicators="">
          <Carousel.Item>
            <div className='img-login'>
              <img
              className="d-block w-100"
              src="./Imagenes/Equipos/Dirui-cs680.png"
              alt="slide"
              />
            </div>
            <Carousel.Caption>Dirui CS-680</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className='img-login'>
              <img
              className="d-block w-100"
              src="./Imagenes/Equipos/Maglumi-x3.png"
              alt="slide"
              />
            </div>
            <Carousel.Caption>Maglumi X3</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className='img-login'>
              <img
              className="d-block w-100"
              src="./Imagenes/Equipos/BC 700m.png"
              alt="slide"
              />
            </div>
            <Carousel.Caption>Mindray BC700</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className='img-login'>
              <img
              className="d-block w-100"
              src="./Imagenes/Equipos/Dirui-1600.png"
              alt="slide"
              />
            </div>
            <Carousel.Caption>Dirui CS-1600</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className='img-login'>
              <a href='https://www.bernardolew.com.ar/maglumi-x8' target='_blank' rel='noreferrer'>
                <img
                className="d-block w-100"
                src="./Imagenes/Equipos/Maglumi-x8.png"
                alt="slide"
                />
              </a>
            </div>
            <Carousel.Caption>Maglumi X8</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className='img-login' style={{paddingTop:'15vh'}}>
              <a href='https://www.bernardolew.com.ar/i15' target='_blank' rel='noreferrer'>
                <img
                className="d-block w-100"
                src="./Imagenes/Equipos/i15.png"
                alt="slide"
                />
              </a>
            </div>
            <Carousel.Caption>Point of Care - Edan i15</Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <div className='img-login'>
              <a href='https://www.bernardolew.com.ar/finecare-fia-meter-plus' target='_blank' rel='noreferrer'>
                <img
                className="d-block w-100"
                src="./Imagenes/Equipos/Finecare Plus.png"
                alt="slide"
                />
              </a>
            </div>
            <Carousel.Caption>Point of Care Finecare™ FIA Meter Plus</Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className='login-col'>
        <form onSubmit={handleSubmit} id="form">
          <div className='box-login'>
            <img className='imgLogo' src="./Imagenes/Orbital-logo.png" alt="img" />
            <div className='second-column-item'>

              <input onChange={(e) => { setUsername(e.target.value) }} value={username || ''} id='user' className='user' placeholder='Usuario' autoFocus />

              <input onChange={(e) => { setPasswordData(e.target.value) }} value={passwordData || ''} id='password' className='password' type="password" placeholder='Contraseña' autoComplete="on" />

              <button className='login-button' type='submit'>Ingresar</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login